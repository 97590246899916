<template>
		<a-modal
			:title="title"
			:visible="visible"
			@ok="clickOk"
			@cancel="close"
			>
			<div class="container">
				<div class="title" hidden>{{title}}</div>
				<div class="content">
					<slot></slot>
				</div>
				<div class="row" hidden>
					<div class="btn-action btn-cancel clickable" @click="close">取消</div>
					<div class="btn-action clickable" @click="clickOk">确定</div>
				</div>
			</div>
		</a-modal>
</template>

<script>
	export default {
		props:["title"],
		data(){
			return {
				visible:false
			}
		},
		methods:{
			open(){
				this.visible=true
			},
			close(){
				this.visible=false
			},
			clickOk(){
				this.visible=false
				this.$emit("ok")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		background: #FFF;

		.title{
			text-align: center;
			font-size: 30px;
			border-bottom: 1px solid #DDD;
		}

		.content{

		}

		.row{
			display: flex;
			border-top:1px solid #DDD;
		}

		.btn-action{
			flex: 1;
			text-align: center;
			font-size: 30px;
		}

		.btn-action:active{
			background: #F0F0F0;
		}

		.btn-cancel{
			border-right: 1px solid #DDD;
			color: #999999;
		}
	}
</style>
